<template>
  <div class="rigsit">
    <van-nav-bar
        title="用户注册"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="tel"
        ref="mobile"
        type="tel"
        name="tel"
        label="+86"
        placeholder="输入手机号码"
        :rules="[{ validator, message: '请输入手机号' }]"
        @blur="telBlur"
      />
      <van-field
        v-model="code"
        type="tel"
        name="code"
        label="验证码"
        placeholder="验证码"
        :rules="[{ required: true, message: '请填写密码' }]"
      >
        <template #button>
          <van-button
            native-type="button"
            size="mini"
            :disabled="btnDisabled"
            type="danger"
            @click="sendcode"
          >
            <van-count-down v-show="!showTxt" :time="time" @finish="finish" ref="daojishi">
              <template v-slot="timeData">
                <span class="seconds">{{ timeData.seconds }}后重新发送</span>
              </template>
            </van-count-down>
            <span v-show="showTxt">发送验证码</span>
          </van-button>
        </template>
      </van-field>
      <van-field
        v-model="password1"
        type="password"
        name="pwd1"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <van-field
        v-model="password2"
        type="password"
        name="pwd2"
        label="重复密码"
        placeholder="再输入一遍密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <van-field
        v-model="agentCode"
        name="agentCode"
        label="邀请码"
        placeholder="邀请码"
      />
      <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: "Rigsit",
  data() {
    return {
      tel: "", 
      password1: "",
      password2: "",
      btnDisabled: true, 
      code: "", // 验证码
      showTxt: false,
      agentCode: "", // 邀请码
      time: -1,
      shareid: 0,
      merchid: 0,
      wxcode: ""
    };
  },
  mounted() {
    this.tel = this.$route.query.tel
    this.shareid = this.$route.query.shareid
    if (!this.shareid) {
      this.shareid = localStorage.getItem("shareid") ? localStorage.getItem("shareid") : 0;
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "用户注册");
    
    if (this.tel) {
      this.telBlur()
    }
    let wxinfo = null;
    wxinfo = localStorage.getItem("DK_WXINFO");
    this.wxcode = this.$route.query.code;
    if (window.isWeixin() && !wxinfo && this.wxcode) {
      this.getToken(this.wxcode);
    }else if (!wxinfo) {
      this.getwxinfo();
    }
    this.init();
  },
  methods: {
    validator(val) {
      return /1\d{10}/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    init() {
      // 获取邀请码
      let _that = null;
      _that = this;
      if (parseInt(_that.shareid) > 0) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getparentByMid",
            _that.$qs.stringify({
              id: _that.shareid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.agentCode = res.data.data.agent_code ? res.data.data.agent_code : "";
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    sendcode(){
      let _that = null;
      _that = this;
      
      if (!_that.btnDisabled && _that.tel && _that.validator(_that.tel)) {
        _that.$axios.post(
          _that.$store.state.domain + "web/user/send_sms",
          _that.$qs.stringify({
            tel: _that.tel,
            type: 'rigister'
          })
        )
        _that.$refs.daojishi.reset();
        _that.time = 60 * 1000;
        _that.btnDisabled = true;
        _that.showTxt = false;  
      }else{
        _that.$refs.mobile.focus();
        _that.$notify('请输入正确的手机号');
      }

    },
    finish(){
      if (this.time >= 0) {
        this.btnDisabled = false;  
      }
      this.showTxt = true;
      console.log(this.btnDisabled,'finish');
    },
    onSubmit() {
      let _that = null;
      _that = this;
      _that.doregist();
    },

    doregist() {
      let _that = null;
      _that = this;
      _that.$toast({
        type: 'loading',
        message: '注册中...',
        overlay: true,
        forbidClick: true,
        duration: 0
      })
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_WXINFO");
      _that.$axios.post(
        _that.$store.state.domain + "web/user/rigister",
        _that.$qs.stringify({
          tel: _that.tel,
          code: _that.code,
          pwd1: _that.password1,
          pwd2: _that.password2,
          openid: openid,
          wxinfo: wxinfo,
          agentcode: _that.agentCode,
          agentid: _that.shareid
        })
      ).then(function(response){
        console.log(response);
        if (response.data && response.data.code == 100000) {
          localStorage.setItem("DK_UID",response.data.data)
          localStorage.setItem("DK_ISLOGIN",true)
          _that.$notify({
            type:'success',
            message: response.data.msg?response.data.msg:'登陆成功'
          })
          _that.$notify({
            type: 'success',
            message: response.data.msg ? response.data.msg : '注册成功'
          });
          if (response.data.user.id) {
            _that.bindupdown(response.data.user.id, _that.shareid); // 绑定上下级关系，获取客服和邀请码
            if (_that.merchid) {
              _that.shopinto(_that.merchid); // 添加进入店铺记录
            }
          }
          
          setTimeout(()=>{
            _that.$toast.clear();
            _that.$router.go(-2);
          },3000)
        }else{
          _that.$notify({
            type: 'warning',
            message: response.data && response.data.msg ? response.data.msg : '注册失败'
          });
          _that.$toast.clear();
        }
      }).catch(function(error){
        console.log(error);
        _that.$toast.clear();
      })
    },
    // 绑定上下级关系
    bindupdown(mid, agentid) {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/bindcode",
          _that.$qs.stringify({
            mid: mid,
            "agent_id": agentid
          })
        )
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 增加进入店铺记录
    shopinto(shopid) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/merch_adduser",
          _that.$qs.stringify({
            merchid: shopid
          })
        )
        .then(res => {
          console.log(res);
        })
        .catch(error => {
          console.log(error);
        })
    },
    onClickLeft(){
      this.$router.go(-1);
    },
    // 手机号输入框失去焦点时查询手机号是否存在
    telBlur() {
      if (this.tel && this.validator(this.tel)) {
        let _that = null;
        _that = this;
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios.post(
          _that.$store.state.domain + 'web/user/get_moblie',
          _that.$qs.stringify({
            mobile: _that.tel,
            type: 'rigister'
          })
        ).then(response => {
          console.log(response);
          if (response.data) {
            if (response.data.code == 100003) { // 手机号不正确
              _that.$refs.mobile.focus();
              _that.$notify({
                  type: 'danger',
                  message: response.data.msg?response.data.msg:'请输入正确的手机号'
              });
              _that.btnDisabled = true
            }else if(response.data.code == 100001){ // 不存在
              console.log('手机号不存在')
              _that.btnDisabled = false
            }else if (response.data.code == 100000) {
                _that.$notify({
                    type: 'warning',
                    message: response.data.msg?response.data.msg:'手机号已存在'
                });
                _that.btnDisabled = true
            }
          }
        }).catch(error => {
          console.log(error);
        })
      }
    },
    // 获取微信信息
    getwxinfo() {
      let _that = null;
      _that = this;
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      if (openid) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
            _that.$qs.stringify({
              openid: openid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.openid == openid) {
              localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data));
            }else{
              if (window.isWeixin()) {
                let wxAuthUrl2 = null;
                wxAuthUrl2 = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
                if (_that.wxcode == null || _that.wxcode === "") {
                  window.location.href = wxAuthUrl2; // [自刷新当前页]
                }
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },

    getToken(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_OPENID", response.data); // 获得用户的openid
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                _that.$router.replace({ name: "Rigsit", query: {tel: _that.tel, shareid: _that.shareid, merchid: _that.merchid} });
                console.log(res);
                if (res.data && res.data.headimgurl && res.data.openid == response.data) {
                  localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data));
                }
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
    }

  }
};
</script>
<style lang="less">
.rigsit {
  height: 100%;
  width: 100%;
  background-color: #fff;
  text-align: left;
  .van-nav-bar {
    .van-icon{
      color: #333;
    }
  }
  .van-form {
    margin-top: 30px;
    .van-cell{
      padding: 10px 20px;
      .van-field__label {
        width: 70px;
      }
      .van-cell__value{
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
          
        .van-field__button{
          height: 24px;
          line-height: 24px;
          .van-button::before{
            border: 0;
          }
          .van-button--default{
            border: 0;
          }
          .van-button--small{
            height: 24px;
          }
        }
      }
      .seconds{
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>